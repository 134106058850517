import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterContentChecked,
} from "@angular/core";
import {
  NgModel,
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { RestService } from "../common/rest.service";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { RequestConfirmationDialogComponent } from "../request-confirmation-dialog/request-confirmation-dialog.component";
import { AuthService } from "../auth.service";
import { Router } from "@angular/router";
import { commonConstants } from "../common/constants/constants";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { stringify } from "@angular/compiler/src/util";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit, AfterContentChecked {
  projectVerified: boolean;
  applicationNames: any[];
  userRole: string;
  superAdmin: boolean;
  otherUsers: boolean;
  projectIdOrNameObj: string;
  requestButnDisable: boolean;
  verifyButnDisable: boolean;
  roles: any[];
  apps: any[];
  dialogResult: false;
  unselectedApps: any[] = [];
  //projectIdOrNameTarget: any[] = [];
  loginMsg = commonConstants.loginErrorMsg;
  public signUpFormGroup: FormGroup;
  projectArray: any[];
  filteredOptions: Observable<string[]>;
  fullProjectCodeAndName: string;
  project: any;
  selectedProject: any;

  constructor(
    private cdRef: ChangeDetectorRef,
    private restService: RestService,
    public formBuilder: FormBuilder,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public authService: AuthService,
    private cookie: CookieService,
    private router: Router
  ) {
    this.signUpFormGroup = formBuilder.group({
      role: ["", Validators.compose([Validators.required])],
      projectIdOrName: ["", Validators.compose([Validators.required])],
      remarks: [""],
      selectedApps: [[], Validators.compose([Validators.required])],
    });
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  openDialog(title: string, message: string): void {
    const dialogRef = this.dialog.open(RequestConfirmationDialogComponent, {
      width: "38%",
      data: {
        title,
        message,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.dialogResult = result;
      if (this.dialogResult) {
        this.sendRequest(); // Sending Request to Server
        this.dialogResult = false;
      }
    });
  }

  equals(objOne, objTwo) {
    if (typeof objOne !== "undefined" && typeof objTwo !== "undefined") {
      return objOne.id === objTwo.id;
    }
  }

  selectAll(checkAll, select: NgModel, values) {
    if (checkAll) {
      select.update.emit(values);
    } else {
      select.update.emit([]);
    }
  }

  ngOnInit() {
    this.superAdmin = false;
    this.otherUsers = false;

    this.requestButnDisable = true;
    this.verifyButnDisable = true;

    // Getting Roles
    this.restService.getActiveRoles().subscribe(
      (result) => {
        this.roles = result;
      },
      (error) => {
        if (typeof error.message !== "undefined") {
          this.toastr.error(error.message);
        } else if (typeof error.error !== "undefined") {
          if (error.error === "login required") {
            this.toastr.error(this.loginMsg);
            this.router.navigate(["/dashboard"]);
          } else {
            this.toastr.error(error.message);
          }
        }
      }
    );
    this.getAllProjectDetails();
    this.filteredOptions = this.signUpFormGroup
      .get("projectIdOrName")
      .valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value))
      );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    if (!this.projectArray) {
      console.warn("projectArray is not defined or empty.");
      return [];
    }

    return this.projectArray.filter(
      (option) =>
        option.name.toLowerCase().includes(filterValue) ||
        option.code.toLowerCase().includes(filterValue)
    );
  }

  getUncheckedApps(appName: string, isChecked: boolean) {
    if (!isChecked && !this.unselectedApps.includes(appName)) {
      this.unselectedApps.push(appName);
    } else if (isChecked && this.unselectedApps.includes(appName)) {
      const index = this.unselectedApps.indexOf(appName);
      this.unselectedApps.splice(index, 1);
    }
  }

  onProjectNameChange() {
    this.projectVerified = false;
    this.requestButnDisable = true;
    this.verifyButnDisable = false;
  }

  isEqual(arr1: any, arr2: any) {
    // if length is not equal
    if (arr1.length !== arr2.length) {
      return 0;
    } else {
      // comparing each element of array
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return 0;
        }
      }
      return 1;
    }
  }

  request() {
    this.fullProjectCodeAndName =
      this.signUpFormGroup.get("projectIdOrName").value;

    this.signUpFormGroup.setControl(
      "unselectedApps",
      this.formBuilder.array(this.unselectedApps || [])
    );

    const projectValue = this.signUpFormGroup.get("projectIdOrName").value;
    this.signUpFormGroup.controls["projectIdOrName"].setValue(projectValue);

    let appStatus: any[] = [];
    let appNames: any[] = [];

    // Ensure pre-checked apps are considered only if 'this.apps' is properly initialized
    const preCheckedApps = Array.isArray(this.apps)
      ? this.apps.filter((app) => app.checked === "true").map((app) => app.name)
      : [];

    const selectedApps = this.signUpFormGroup.get("selectedApps").value || [];
    const allSelectedApps = Array.from(
      new Set([...selectedApps, ...preCheckedApps])
    );

    if (this.signUpFormGroup.get("role").value === "Super Admin") {
      this.openDialog(
        "Confirmation",
        "Do you wish to request for Super Admin access?"
      );
    } else {
      // Reset appStatus and appNames to empty arrays if role is not Super Admin
      appStatus = [];
      appNames = [];

      if (projectValue === "") {
        this.toastr.warning("Please select relevant Project.");
        return;
      }

      if (allSelectedApps.length === 0) {
        this.toastr.warning("Please select relevant Application.");
        return;
      }

      if (Array.isArray(this.apps)) {
        for (const elements of this.apps) {
          if (typeof elements.status !== "undefined") {
            appStatus.push(elements.status);
          }
          if (
            elements.checked === "true" &&
            typeof elements.name !== "undefined"
          ) {
            appNames.push(elements.name);
          }
        }
      }

      const compareResult = this.isEqual(allSelectedApps, appNames);

      const isRequested = (currentValue) => currentValue === "Requested";
      const isApproved = (currentValue) => currentValue === "Approved";
      const isRequestedOrApproved = (currentValue) =>
        currentValue === "Approved" || currentValue === "Requested";
      const isActive = (currentValue) => currentValue === "ACTIVE";
      const previousRole = this.userRole;
      const toRole = this.signUpFormGroup.get("role").value;

      if (appStatus.every(isRequested)) {
        // Handle already requested or new app requests
        if (compareResult && previousRole === toRole) {
          this.toastr.error(
            "You already have requested for " +
              previousRole +
              " role for selected app!"
          );
          // open this dialog when same app is requested on top of the already requested app for different user role
        } else if (compareResult && previousRole != toRole) {
          this.toastr.error(
            "You already have requested for " +
              previousRole +
              " role for selected app, please let that approve and then revoke it, then again request for " +
              toRole
          );
        } else if (allSelectedApps.length > appNames.length) {
          this.openDialog(
            "Confirmation",
            "Your request will be sent for approval. Do you want to continue?"
          );
        } else if (allSelectedApps.length < appNames.length) {
          this.openDialog(
            "Confirmation",
            "Your request will be processed. Do you want to continue?"
          );
        } else {
          this.openDialog(
            "Confirmation",
            "Your request will be sent for approval. Do you want to continue?"
          );
        }
      } else if (appStatus.every(isApproved)) {
        // Handle already approved apps or new approvals
        if (compareResult && previousRole === toRole) {
          this.toastr.error(
            "You already have role " + previousRole + " for selected app!"
          );
        } else if (compareResult && previousRole != toRole) {
          this.toastr.error(
            "You already have approved role " +
              previousRole +
              " for selected app, please revoke that and then request for " +
              toRole
          );
        } else if (allSelectedApps.length > appNames.length) {
          this.openDialog(
            "Confirmation",
            "Your request will be sent for approval. Do you want to continue?"
          );
        } else if (allSelectedApps.length < appNames.length) {
          this.openDialog(
            "Confirmation",
            "Your request will be processed. Do you want to continue?"
          );
        } else {
          this.openDialog(
            "Confirmation",
            "Your request will be sent for approval. Do you want to continue?"
          );
        }
      } else if (appStatus.every(isActive)) {
        if (allSelectedApps.length) {
          this.sendRequest();
        } else {
          this.toastr.warning(
            "Please select an application to map with project"
          );
        }
      } else if (appStatus.every(isRequestedOrApproved)) {
        // Handle requested or approved apps
        if (compareResult && previousRole === toRole) {
          this.toastr.error(
            "You already have requested for " +
              previousRole +
              " role for selected app!"
          );
        } else if (compareResult && previousRole != toRole) {
          this.toastr.error(
            "You already have requested for " +
              previousRole +
              " role for selected app, please let that approve and then revoke it, then again request for " +
              toRole
          );
        } else if (allSelectedApps.length > appNames.length) {
          this.openDialog(
            "Confirmation",
            "Your request will be sent for approval. Do you want to continue?"
          );
        } else if (allSelectedApps.length < appNames.length) {
          this.openDialog(
            "Confirmation",
            "Your request will be processed. Do you want to continue?"
          );
        } else {
          this.openDialog(
            "Confirmation",
            "Your request will be sent for approval. Do you want to continue?"
          );
        }
      }
    }
  }

  onProjectSelected(project: any) {
    if (!project || typeof project.code === "undefined") {
      console.error("Invalid project selected: ", project);
      this.toastr.warning(
        "Invalid project selection. Please select a valid project."
      );
      return;
    }

    // Store project as key-value pair in a custom property
    this.selectedProject = {
      projectCode: project.code,
      projectName: project.name,
    };

    // Set only projectCode in the form control
    this.signUpFormGroup.get("projectIdOrName").setValue(project.code);

    this.verify({ code: project.code, name: project.name });
  }

  sendRequest() {
    const role = this.signUpFormGroup.get("role").value;
    const projectIdOrName = this.signUpFormGroup.get("projectIdOrName").value;

    // If the role is not "Super Admin" and project is not selected, show a warning
    if (role !== "Super Admin" && !projectIdOrName) {
      console.error("No valid project selected for submission.");
      this.toastr.warning(
        "Please select a valid project before submitting the form."
      );
      return;
    }

    // Request payload
    const requestData = {
      ...this.signUpFormGroup.value,
      // For non-Super Admin roles, include projectCode in the request payload
      projectIdOrName:
        role === "Super Admin" ? undefined : this.selectedProject.projectCode,
    };

    console.log("Sending request with:", requestData);

    this.restService.signUp(requestData).subscribe(
      (result) => {
        this.toastr.success(result.message);

        // Delay the page reload to allow the success message to show
        setTimeout(() => {
          window.top.location.reload();
        }, 2000); // 2 seconds delay

        this.requestButnDisable = true;

        this.apps = [];
        this.signUpFormGroup.controls.role.clearValidators();
        this.signUpFormGroup.controls.projectIdOrName.clearValidators();
        this.signUpFormGroup.controls.selectedApps.clearValidators();
        this.signUpFormGroup.reset();

        this.projectVerified = false;
        this.superAdmin = false;
        this.otherUsers = false;

        const displayValue = `${this.selectedProject.projectCode} - ${this.selectedProject.projectName}`;
        this.signUpFormGroup.controls.projectIdOrName.setValue(displayValue);
        this.signUpFormGroup.controls.selectedApps.setValue([]);
        this.unselectedApps = [];
        this.selectedProject = null;
      },
      (error) => {
        this.toastr.error(error.error.message, "", { timeOut: 50000 });
      }
    );
  }

  verify(project: { code: string; name: string }) {
    if (!project || typeof project.code === "undefined") {
      return;
    }

    const projectDisplayValue = `${project.code} - ${project.name}`;
    this.signUpFormGroup.get("projectIdOrName").setValue(projectDisplayValue);

    this.restService.getProject(project.code).subscribe(
      (result) => {
        let projectHasLOP = false;
        result.apps.forEach((app) => {
          if (app.hasOwnProperty("role")) {
            this.userRole = app.role;
          }
          if (app.name === "LOP") {
            projectHasLOP = true;
          }
        });

        this.projectIdOrNameObj = result.project;
        this.projectVerified = true;
        this.requestButnDisable = false;

        if (projectHasLOP) {
          const projectCode = result.project.code;
          this.restService
            .getUserRoleInIcmmsLop(projectCode)
            .subscribe((res) => {
              this.apps = [];

              const role = res.response;

              if (role === "CONTRACTOR") {
                // LOP contractor user - Request access only for LOP app
                result.apps.forEach((app) => {
                  if (app.name.toUpperCase() === "LOP") {
                    this.apps.push(app);
                  }
                });
                // Setting Role to Site User for LOP contractor
                if (this.signUpFormGroup.get("role").value !== "Site User") {
                  this.signUpFormGroup.get("role").setValue("Site User");
                }

                this.toastr.info("LOP contractor can access only LOP App", "", {
                  timeOut: 8000,
                });
              } else if (role === "NOT FOUND" || role === "ERROR") {
                // User not available in ICMMS or system is down - Request access all apps except LOP
                this.apps = result.apps;
                this.apps.forEach((app) => {
                  if (app.name.toUpperCase() === "LOP") {
                    app.disabled = true;
                  }
                });
                if (role === "ERROR") {
                  this.toastr.warning(
                    "Request access for LOP app is restricted due to error in ICMMS system",
                    "",
                    { timeOut: 8000 }
                  );
                }
              } else {
                // User has other role than contractor - Access all apps
                this.apps = result.apps;
              }
            });
        } else {
          this.apps = result.apps;
        }
      },
      (error) => {
        this.toastr.error(error.error.message);
        this.projectVerified = false;
        this.requestButnDisable = true;
      }
    );
    this.unselectedApps = []; // clearing unselected apps
  }

  // event handler for the select element's change event
  roleChangeHandler(event: any) {
    if (event.value === "Super Admin") {
      this.superAdmin = true;
      this.otherUsers = false;
      this.requestButnDisable = false;
      this.projectVerified = false;
    } else {
      this.otherUsers = true;
      this.superAdmin = false;
      this.requestButnDisable = true;
      this.projectVerified = false;
    }
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.authService.logout();
    this.cookie.deleteAll();
    console.log("Loggin out..");
    this.router.navigate([""]);
  }

  getAllProjectDetails() {
    this.restService.getAllProjectDetails().subscribe(
      (res) => {
        this.projectArray = res.filter(
          (p) => p.name !== "ALL" && p.status == "ACTIVE"
        );
      },
      (error) => {
        if (
          error.error === "Login required" ||
          error.message.includes("Login required")
        ) {
          this.router.navigate(["/dashboard"]);
        }
      }
    );
  }
}
