import { Component, OnInit } from "@angular/core";
import { AuthService } from "./auth.service";
import { Observable, Subscription } from "rxjs";
import { User } from "./common/User.model";
import { Router } from "@angular/router";
import { RestService } from "./common/rest.service";
import { NavLink } from "./navLink";
import { MsalAuthService } from "./msal-auth.service";
import { MsalBroadcastService } from "@azure/msal-angular";
import { filter, map } from "rxjs/operators";
import {
  InteractionStatus,
  AuthenticationResult,
  EventType,
} from "@azure/msal-browser";
import { CookieService } from "ngx-cookie-service";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "usermanagement-ui";
  userLoggedIn = false;
  subscription: Subscription;
  profileJson: string = null;
  userDetails: User;
  navLinks: NavLink[] = [];
  activeLinkIndex = -1;
  existRoleUser: boolean;
  navBaseLink: string;
  isMyId: boolean;
  userName: string;
  auth: string;
  constructor(
    public authService: AuthService,
    private router: Router,
    private restService: RestService,
    private msalAuth: MsalAuthService,
    private msalBroadcastService: MsalBroadcastService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private cookie: CookieService
  ) {}

  ngOnInit() {
    localStorage.setItem("tokenExpiryCheck", "false");
    this.auth = sessionStorage.getItem("auth");

    if (this.auth === "azure") {
      this.msalBroadcastService.msalSubject$
        .pipe(filter((value) => value.eventType === EventType.LOGIN_SUCCESS))
        .subscribe((eventMessage) => {
          const authResult = eventMessage.payload as AuthenticationResult;
          sessionStorage.setItem("accessToken", authResult.idToken);
          sessionStorage.setItem("idToken", authResult.idToken);
          sessionStorage.setItem("expiresOn", authResult.expiresOn.toString());
        });

      this.msalAuth.updateLoggedInStatus();

      this.msalBroadcastService.inProgress$
        .pipe(filter((value) => value === InteractionStatus.None))
        .subscribe(() => this.setTabs());

      this.userLoggedIn = this.msalAuth.loggedIn;
      this.userName = this.msalAuth.loggedInUser;
    } else if (this.auth === "myid") {
      this.authService.userProfile$.subscribe((profile) => {
        this.subscription = this.authService.userLoggedIn$.subscribe(
          (userLoggedIn) => (this.userLoggedIn = userLoggedIn)
        );
        this.userDetails = JSON.parse(this.profileJson) as User;

        if (this.userDetails) {
          this.subscription = this.authService.userLoggedIn$.subscribe(
            (userLoggedIn) => (this.userLoggedIn = userLoggedIn)
          );
          sessionStorage.setItem("userName", this.userDetails.name);
          sessionStorage.setItem(
            "currentGid",
            this.userDetails.sub.split("|")[4]
          );
        }

        if (sessionStorage.getItem("userName")) {
          this.userLoggedIn = true;
          this.userName = sessionStorage.getItem("userName");
        }
      });
      this.setTabs();
    }
  }

  private setTabs() {
    this.spinner.show();

    this.restService.getRoleForUser().subscribe(
      (result) => {
        this.spinner.hide();
        this.existRoleUser = result;
        if (this.existRoleUser) {
          this.fillNavLink();
        } else {
          this.addOnlyRequestNavLink();
        }

        const tokenExpired =
          localStorage.getItem("tokenExpiryCheck") === "true";

        if (tokenExpired) {
          this.toastr.error("Login Required/Expired. Please Login Again");

          setTimeout(() => {
            sessionStorage.clear();
            localStorage.clear();
            this.authService.logout();
            this.cookie.deleteAll();
            this.router.navigate([""]).then(() => {
              setTimeout(() => {
                this.spinner.hide();
              }, 1000);
            });
          }, 5000);
        }
      },
      (error) => {
        if (
          (error.error && error.error.error === "Unauthorized") ||
          error.message.includes("Login required")
        ) {
          this.toastr.error(
            "You are not a Valid User. For more support, please contact Siemens Energy Superior ",
            undefined,
            { timeOut: 10000 }
          );
        } else {
          this.spinner.hide();
        }
      }
    );
  }

  fillNavLink() {
    const tabs = [
      {
        label: "Dashboard",
        link: "/dashboard",
        index: 0,
      },
      {
        label: "User/Project Management",
        link: "/usermanagement",
        index: 1,
      },
      {
        label: "Request Access",
        link: "/addUser",
        index: 2,
      },
    ];
    this.navLinks = tabs;
    sessionStorage.setItem("rootUrl", this.navLinks[0].link);
  }

  addOnlyRequestNavLink() {
    const tabs = [
      {
        label: "Dashboard",
        link: "/dashboard",
        index: 0,
      },
      {
        label: "Request Access",
        link: "/addUser",
        index: 1,
      },
    ];

    this.navLinks = tabs;
    sessionStorage.setItem("rootUrl", this.navLinks[0].link);
  }

  loginAzureAdClicked() {
    this.isMyId = false;
    sessionStorage.setItem("auth", "azure");

    this.msalAuth.login();

    setTimeout(() => {
      this.msalBroadcastService.inProgress$
        .pipe(filter((value) => value === InteractionStatus.None))
        .subscribe(() => {
          this.msalAuth.updateLoggedInStatus();
          this.setTabs();
        });
    }, 1000);
  }

  logout_azure() {
    sessionStorage.clear();
    localStorage.clear();
    this.msalAuth.logout();
  }

  logout_myId() {
    sessionStorage.clear();
    localStorage.clear();
    this.authService.logout();
  }

  loginMyIdClicked() {
    sessionStorage.setItem("auth", "myid");
    this.isMyId = true;
    this.authService.userProfile$.subscribe((profile) => {
      this.subscription = this.authService.userLoggedIn$.subscribe(
        (userLoggedIn) => (this.userLoggedIn = userLoggedIn)
      );
      this.userDetails = JSON.parse(this.profileJson) as User;

      if (this.userDetails) {
        this.subscription = this.authService.userLoggedIn$.subscribe(
          (userLoggedIn) => (this.userLoggedIn = userLoggedIn)
        );
        sessionStorage.setItem("userName", this.userDetails.name);
        sessionStorage.setItem(
          "currentGid",
          this.userDetails.sub.split("|")[4]
        );
        this.userLoggedIn = true;
      }

      if (sessionStorage.getItem("userName")) {
        this.userLoggedIn = true;
      }
    });
    this.setTabs();
    this.authService.login();
  }
}
