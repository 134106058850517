import { Inject, Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subscription,
  of,
  throwError,
} from "rxjs";
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from "@azure/msal-angular";
import { AuthenticationResult, SilentRequest } from "@azure/msal-browser";
import {
  catchError,
  filter,
  finalize,
  mergeMap,
  switchMap,
  take,
} from "rxjs/operators";
import { AuthService } from "../auth.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class InterceptorService implements HttpInterceptor {
  private accessToken: string | null;
  private isRefreshingToken = false;
  private refreshTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msal: MsalService,
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private cookie: CookieService
  ) {
    // Retrieve token from cookies or sessionStorage
    this.accessToken = this.getToken("accessToken");

    // Schedule token renewal
    this.scheduleTokenRenewal();
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const idToken = this.getToken("idToken");
    const accessToken = this.getToken("accessToken");

    if (idToken && !this.isTokenExpired(idToken)) {
      return this.handleRequestWithToken(req, next, idToken);
    } else if (accessToken && !this.isTokenExpired(accessToken)) {
      return this.handleRequestWithToken(req, next, accessToken);
    } else if (sessionStorage.getItem("auth") === "azure") {
      return this.acquireTokenSilently(req, next);
    } else {
      return this.handleNonAzureAuth(req, next);
    }
  }

  private handleRequestWithToken(
    req: HttpRequest<any>,
    next: HttpHandler,
    token: string
  ): Observable<HttpEvent<any>> {
    const headers = req.headers.set("Authorization", `Bearer ${token}`);
    const requestClone = req.clone({ headers });
    return next.handle(requestClone);
  }

  private acquireTokenSilently(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.isRefreshingToken) {
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => this.handleRequestWithToken(req, next, token!))
      );
    }

    this.isRefreshingToken = true;
    this.refreshTokenSubject.next(null);

    return this.msal
      .acquireTokenSilent({
        ...this.msalGuardConfig.authRequest,
      } as SilentRequest)
      .pipe(
        catchError((error) => {
          if (
            error.errorCode === "interaction_required" ||
            error.status === 400 ||
            error.error === "Unauthorized"
          ) {
            console.warn("Clearing cache due to token acquisition failure.");
            this.authService.logout();
          }

          // If idToken acquisition fails, fallback to accessToken
          if (this.accessToken && !this.isTokenExpired(this.accessToken)) {
            return this.handleRequestWithToken(req, next, this.accessToken);
          } else {
            return this.handleNonAzureAuth(req, next);
          }
        }),
        switchMap((result: AuthenticationResult) => {
          this.storeToken(result.idToken, result.expiresOn.toString());
          this.refreshTokenSubject.next(result.idToken);
          return this.handleRequestWithToken(req, next, result.idToken);
        }),
        catchError((error) => {
          this.isRefreshingToken = false;
          console.warn("Token renewal failed, logging out.");
          this.handleTokenError();
          return throwError(() => error);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
  }

  private handleNonAzureAuth(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.getTokenSilently$().pipe(
      switchMap((token) => {
        return this.handleRequestWithToken(req, next, token);
      }),
      catchError((error) => {
        if (error && error.error && error.error.error === "Unauthorized") {
          this.handleTokenError();
        }

        return throwError(error);
      })
    );
  }

  private handleTokenError() {
    console.warn("Token error detected. Clearing authentication data.");

    // Clear authentication data
    this.clearAuthData();

    this.authService.logout();
  }

  private isTokenExpired(token: string | null): boolean {
    if (!token) {
      localStorage.setItem("tokenExpiryCheck", "true");
      return true;
    }

    try {
      const tokenParts = token.split(".");

      // Ensure the token has exactly 3 parts
      if (tokenParts.length !== 3 || tokenParts[2].trim() === "") {
        localStorage.setItem("tokenExpiryCheck", "true");
        return true; // Treat it as an expired/invalid token
      }

      const payload = JSON.parse(atob(tokenParts[1]));

      // Ensure 'exp' exists in the payload
      if (!payload.exp) {
        localStorage.setItem("tokenExpiryCheck", "true");
        return true;
      }

      const isExpired = Date.now() >= payload.exp * 1000;

      if (isExpired) {
        localStorage.setItem("tokenExpiryCheck", "true");
      }

      return isExpired;
    } catch (error) {
      localStorage.setItem("tokenExpiryCheck", "true");
      return true;
    }
  }

  private storeToken(idToken: string, expiresOn: string) {
    sessionStorage.setItem("idToken", idToken);
    sessionStorage.setItem("expiresOn", expiresOn);

    // Store tokens in cookies with expiry
    const expiryDate = new Date(Number(expiresOn) * 1000);
    this.cookie.set("idToken", idToken, expiryDate, "/");
    this.cookie.set("accessToken", idToken, expiryDate, "/");
  }

  private getToken(tokenName: string): string | null {
    return this.cookie.get(tokenName) || sessionStorage.getItem(tokenName);
  }

  private clearAuthData() {
    ["accessToken", "idToken", "userName", "auth"].forEach((item) =>
      this.cookie.delete(item, "/")
    );
    this.cookie.deleteAll();
    sessionStorage.clear();
    localStorage.clear();
  }

  private scheduleTokenRenewal() {
    const accessToken = this.getToken("accessToken");
    if (accessToken && !this.isTokenExpired(accessToken)) {
      const payload = JSON.parse(atob(accessToken.split(".")[1]));
      const expiryTime = payload.exp * 1000;
      const renewTime = expiryTime - Date.now() - 300000; // Renew 5 minutes before expiry

      if (renewTime > 0) {
        setTimeout(() => this.refreshToken(), renewTime);
      }
    }
  }

  private refreshToken() {
    this.msal
      .acquireTokenSilent({
        ...this.msalGuardConfig.authRequest,
      } as SilentRequest)
      .pipe(
        switchMap((result: AuthenticationResult) => {
          this.storeToken(result.idToken, result.expiresOn.toString());
          this.scheduleTokenRenewal(); // Reschedule for next expiry
          return of(true);
        }),
        catchError(() => {
          console.warn("Failed to refresh token. Logging out.");
          this.handleTokenError();
          return of(false);
        })
      )
      .subscribe();
  }
}
